import styled from 'styled-components';
import IconArrowYellowRight from '../../images/icon-arrow-yellow-right.png';

export const Body = styled.div`
  padding: 40px 90px;
  @media (max-width: 991px) {
    padding: 35px;
  }
`;

export const Header3 = styled.h2`
  color: #48b4f1;
  font-family: Gotham-Light, arial, sans-serif;
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 35px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;
export const ParaText = styled.div`
  color: #666;
  white-space: pre-wrap;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    line-height: 22px;
  }
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
  }
`;

export const Header2 = styled.h2`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #037ec4;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export const Header = styled.h1`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #00457c;
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
    width: 100%;
  }
`;
